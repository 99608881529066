import { Injectable } from '@angular/core';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction } from '../../interfaces';
import { ScheduleConflictRequest } from './schedule-conflict.model';

@Injectable()
export class ScheduleConflictApi {
  private endpoint = 'planning/conflicts';

  public constructor(private gateway: ApiGateway) {}

  public getAllConflicts(conflictRequest: ScheduleConflictRequest, dispatchStart?: UnsafeAction) {
    return this.gateway.get(
      this.endpoint,
      { params: objectToSearchParams(conflictRequest, ArrayStrategy.COMMA_SEPARATED) },
      dispatchStart,
    );
  }

  public getAbsenceConflicts(conflictRequest: ScheduleConflictRequest, dispatchStart?: UnsafeAction) {
    const { minDate, maxDate, employeeIds } = conflictRequest;

    const requestData = {
      minDate,
      maxDate,
    };

    if (employeeIds?.length > 0) {
      requestData['employee_ids'] = employeeIds.join(',');
    }

    return this.gateway.get(this.endpoint + '/time_off', { params: objectToSearchParams(requestData) }, dispatchStart);
  }

  public getAvailabilitiesConflict(conflictRequest: ScheduleConflictRequest, dispatchStart?: UnsafeAction) {
    const { minDate, maxDate, employeeIds } = conflictRequest;

    const requestData = {
      minDate,
      maxDate,
    };

    if (employeeIds?.length > 0) {
      requestData['employee_ids'] = employeeIds.join(',');
    }

    return this.gateway.get(
      this.endpoint + '/availabilities',
      { params: objectToSearchParams(requestData) },
      dispatchStart,
    );
  }
}
