import { throwError as observableThrowError, Observable } from 'rxjs';

import { map, tap, catchError } from 'rxjs/operators';
///<reference path="../../../shared/date.helper.ts"/>

import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { AppState } from '../../index';
import { createSelector } from 'reselect';
import { mapEntities } from '../../shared/entity.helper';
import { AvailabilityApi, AvailabilityLoadRequest } from './availability.api';
import { AvailabilityAction } from './availability.action';
import { AvailabilityModel } from './availability.model';
import { parseDate, periodFilter, periodDateRangeFilter } from '../../../shared/date.helper';
import { hasPermission } from '../../auth/permission.helper';
import mapValues from 'lodash-es/mapValues';
import some from 'lodash-es/some';
import { AbsenceModel } from '../absence/absence.model';
import { isColorDark, hexToRGB } from '../../../shared/contrast.helper';
import { subDays, toDate, addDays, isWithinInterval, parse } from 'date-fns';
import { ScheduleFilterPeriod } from '../../page-filters/page-filters.model';
import { TeamType } from '../team/team.model';
import { ScheduleConflictService } from '../schedule-conflict/schedule-conflict.service';

@Injectable()
export class AvailabilityService {
  constructor(
    private store: Store<AppState>,
    private api: AvailabilityApi,
    private conflictService: ScheduleConflictService
  ) {}

  load(requestData: AvailabilityLoadRequest, updateStore: boolean = true) {
    return this.api.load(requestData, AvailabilityAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(AvailabilityAction.loadSuccess(response));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AvailabilityAction.loadFailed(response));
        return observableThrowError(response);
      })
    );
  }

  add(activityData): Observable<any> {
    return this.api.add(activityData).pipe(
      tap((response) => {
        this.store.dispatch(AvailabilityAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(AvailabilityAction.addFailed(response));
        return observableThrowError(response);
      })
    );
  }
}

export const getAvailabilityState = (state: AppState) => state.orm.availabilities;

export const getAvailabilityIds = compose((state) => state.items, getAvailabilityState);
export const getAvailabilityEntities = createSelector(getAvailabilityState, (state) => mapValues(
    state.itemsById,
    (availability: AvailabilityModel): AvailabilityModel => {
      const allDay = availability.type === 'Available all day' || availability.type === 'Unavailable all day';

      const starttime = allDay ? '00:00:00' : availability.starttime;
      const endtime = allDay ? '00:00:00' : availability.endtime;

      let startDateTime = parse(availability.date + ' ' + starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
      let endDateTime = parse(availability.date + ' ' + endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

      if (endDateTime <= startDateTime) {
        endDateTime = addDays(endDateTime, 1);
      }

      const available = availability.type === 'Available all day' || availability.type === 'Available from';

      const color = available ? '#8ad646' : '#98A0AB'; // green / gray
      const color_rgb = hexToRGB(color);
      const color_is_dark = isColorDark(color);

      return {
        ...availability,
        startDateTime,
        endDateTime,
        color,
        color_is_dark,
        color_rgb,
      };
    }
  ));

export const getAvailabilities = createSelector(getAvailabilityIds, getAvailabilityEntities, (ids, entities) => mapEntities(ids, entities).filter((availability: AvailabilityModel) => !!availability.type));

export const getAvailabilitiesForUser = (userId: string) =>
  createSelector(getAvailabilities, (availabilities: AvailabilityModel[]) =>
    availabilities.filter((availability) => availability.user_id === userId)
  );

export const availabilityForPeriod = (
  period: ScheduleFilterPeriod,
  permissionState,
  employeeTeamDepartments,
  availability: AvailabilityModel[]
): AvailabilityModel[] => {
  let filter;
  if (period.periodType === 'day') {
    filter = periodDateRangeFilter(period.range.start, period.range.end);
  } else {
    filter = periodFilter(period.minDate, period.maxDate);
  }

  return (
    availability
      //period filter
      .filter(filter)
      //check view permission
      .filter((availabilityRow) => {
        let employeeDepartments = employeeTeamDepartments[availabilityRow.user_id];
        if (employeeDepartments.includes(TeamType.FLEXPOOL)) {
          employeeDepartments = null;
        }
        return hasPermission(
          {
            permissions: ['View availability', 'View own availability'],
            userId: availabilityRow.user_id,
            departments: employeeDepartments || [],
          },
          permissionState
        );
      })
      // can edit
      .map((availabilityRow: AvailabilityModel) => {
        const canEdit = hasPermission(
          {
            permissions: ['Edit availability', 'Edit own availability'],
            userId: availabilityRow.user_id,
            departments: employeeTeamDepartments[availabilityRow.user_id] || [],
          },
          permissionState
        );

        return {
          ...availabilityRow,
          canEdit,
        };
      })
  );
};

/**
 * determine if availability has overlap with absence.
 * @param {AbsenceModel[]} absence
 */
export const hasAbsenceOverlap = (absence: AbsenceModel[]) => (availability: AvailabilityModel) => {
  const userId = availability.user_id;

  const hasOverlap = some(absence, (absenceItem: AbsenceModel) => {
    if (absenceItem.user_id !== userId) {
      return false;
    }

    return absenceItem.endDateTime > availability.startDateTime && absenceItem.startDateTime < availability.endDateTime;
  });

  return hasOverlap;
};

export const getAvailabilitiesWithinPeriod = (minDate: string, maxDate: string) => createSelector(getAvailabilities, (availabilities: AvailabilityModel[]) => {
    const startDate = parseDate(minDate);
    const endDate = parseDate(maxDate);

    return availabilities.filter((availability) => {
      const date = parseDate(availability.date);

      return isWithinInterval(date, { start: startDate, end: endDate });
    });
  });

export const getAvailabilitiesForUserInPeriod = (userId: string, minDate: string, maxDate: string) => createSelector(getAvailabilitiesWithinPeriod(minDate, maxDate), (availabilities: AvailabilityModel[]) => availabilities.filter((availability) => availability.user_id === userId));
