import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { AvailabilitySchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { objectToSearchParams } from '../../../api/api.helper';
import { assignSchemaEntity } from '../../shared/assign';

export interface AvailabilityLoadRequest {
  userId?: string;
  minDate?: string;
  maxDate?: string;
}

@Injectable()
export class AvailabilityApi {
  private endpoint = 'availabilities/';

  constructor(private gateway: ApiGateway) {}

  add(data): Observable<any> {
    return this.gateway.post(this.endpoint, data).pipe(
      map((res) => reformatEntityResponse('Availability', res)),
      map((data) => normalize(data, arrayOf(AvailabilitySchema), { assignEntity: assignSchemaEntity }))
    );
  }

  load(requestData: AvailabilityLoadRequest, dispatchStart?: Action): Observable<any> {
    const search = objectToSearchParams(requestData);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart
      )
      .pipe(
        map((res) => {
          // When requesting the availabilities with a userId we get an entity not a list.
          if (res.Availability) {
            return reformatEntityResponse('Availability', res);
          } else {
            return reformatListResponse('Availability', res);
          }
        }),
        map((data) => normalize(data, arrayOf(AvailabilitySchema), { assignEntity: assignSchemaEntity }))
      );
  }
}
