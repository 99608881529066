import { Dictionary } from '@ngrx/entity';

export enum ConflictTopic {
  AVAILABILITY = 'availability',
  SKILL = 'skill',
  TIME_OFF = 'timeoff',
  SCHEDULE = 'schedule',
}

export interface ScheduleConflictStoreModel {
  id: string;
  conflicts: Dictionary<ScheduleConflictModel[]>;
  timestamp?: number;
}

export interface ScheduleConflictModel {
  occurrence_id: string;
  employee_id: string;
  topic: ConflictTopic;
  message: string;
}

export interface ScheduleConflictRequest {
  minDate?: string;
  maxDate?: string;
  employeeIds?: string[];
  departmentIds?: string[];
}
