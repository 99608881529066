import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { FeatureService } from '../../../startup/feature.service';
import { getPermissionState, hasPermission, PermissionCheck } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { getSelectedSchedulePeriod } from '../../page-filters/page-filters.helper';
import { ScheduleFilterPeriod } from '../../page-filters/page-filters.model';
import { ScheduleConflictAction } from './schedule-conflict.action';
import { ScheduleConflictApi } from './schedule-conflict.api';
import {
  ConflictTopic,
  ScheduleConflictModel,
  ScheduleConflictRequest,
  ScheduleConflictStoreModel,
} from './schedule-conflict.model';

@Injectable()
export class ScheduleConflictService {
  public constructor(
    private api: ScheduleConflictApi,
    private store: Store<AppState>,
    private featureService: FeatureService,
  ) {}

  private getSavePeriod(): Observable<{ minDate: string; maxDate: string }> {
    return this.store.select(getSelectedSchedulePeriod).pipe(
      first(),
      map((filterPeriod: ScheduleFilterPeriod) => ({
        minDate: filterPeriod.minDate,
        maxDate: filterPeriod.maxDate,
      })),
    );
  }

  private hasPermissions() {
    const check: PermissionCheck = {
      permissions: ['Create roster', 'Edit roster', 'Delete roster'],
      userId: 'me',
      departments: 'any',
    };

    return this.store.select(getPermissionState).pipe(
      map((permissionState) => hasPermission(check, permissionState)),
      first(),
    );
  }

  public getAllConflicts(requestData: ScheduleConflictRequest, update = false, updateStore = true) {
    return this.hasPermissions().pipe(
      switchMap((hasPerm: boolean) => {
        if (hasPerm) {
          return this._fetch(
            { ...requestData, employeeIds: [...new Set<string>(requestData.employeeIds)] },
            update,
            updateStore,
          );
        }

        return observableOf(undefined);
      }),
      first(),
    );
  }

  private _fetch(requestData: ScheduleConflictRequest, update = false, updateStore = true) {
    const timestamp = new Date().getTime();
    const action = update
      ? ScheduleConflictAction.update(requestData.employeeIds, timestamp)
      : ScheduleConflictAction.load();

    return this.getSavePeriod().pipe(
      switchMap((period) => {
        const data = {
          ...requestData,
        };

        if (!requestData.minDate || !requestData.maxDate) {
          data.minDate = period.minDate;
          data.maxDate = period.maxDate;
        }

        return this.api.getAllConflicts(data, action).pipe(
          map((response) => {
            const groupedResponse = Object.values(groupBy(response, 'employee_id') ?? {});
            const storeModel: ScheduleConflictStoreModel[] = groupedResponse.map(
              (conflicts: ScheduleConflictModel[]) => ({
                id: conflicts[0].employee_id,
                conflicts: groupBy(conflicts, 'occurrence_id'),
              }),
            );

            if (updateStore) {
              const successAction = update
                ? ScheduleConflictAction.updateSuccess(storeModel, timestamp)
                : ScheduleConflictAction.loadSuccess(storeModel);
              this.store.dispatch(successAction);
            }
            return response;
          }),
          catchError((response) => {
            const failAction = update
              ? ScheduleConflictAction.updateFailed(response)
              : ScheduleConflictAction.loadFailed(response);
            this.store.dispatch(failAction);
            return observableOf(undefined);
          }),
        );
      }),
    );
  }

  private getPeriodData(requestData) {
    return this.getSavePeriod().pipe(
      map((period) => ({
        ...requestData,
        minDate: period.minDate,
        maxDate: period.maxDate,
      })),
    );
  }

  public getAbsenceConflicts(requestData: ScheduleConflictRequest) {
    return this.hasPermissions().pipe(
      switchMap((hasPerm) => {
        if (!hasPerm) {
          return observableOf(undefined);
        }

        return this.getPeriodData(requestData).pipe(switchMap((data) => this._fetchAbsenceConflicts(data)));
      }),
    );
  }

  private _fetchAbsenceConflicts(requestData: ScheduleConflictRequest) {
    return this.api
      .getAbsenceConflicts(
        requestData,
        ScheduleConflictAction.updateSingular(requestData.employeeIds, ConflictTopic.TIME_OFF),
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(ScheduleConflictAction.updateSingularSuccess(response));
        }),
        catchError((response) => {
          this.store.dispatch(ScheduleConflictAction.updateFailed(response));
          return observableOf(undefined);
        }),
      );
  }

  public getAvailabilityConflicts(requestData: ScheduleConflictRequest) {
    return this.hasPermissions().pipe(
      switchMap((hasPerm) => {
        if (!hasPerm) {
          return observableOf(undefined);
        }

        return this.getPeriodData(requestData).pipe(switchMap((data) => this._fetchAvailabilityConflicts(data)));
      }),
    );
  }

  private _fetchAvailabilityConflicts(requestData: ScheduleConflictRequest) {
    return this.api
      .getAvailabilitiesConflict(
        requestData,
        ScheduleConflictAction.updateSingular(requestData.employeeIds, ConflictTopic.AVAILABILITY),
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(ScheduleConflictAction.updateSingularSuccess(response));
        }),
        catchError((response) => {
          this.store.dispatch(ScheduleConflictAction.updateFailed(response));
          return observableOf(undefined);
        }),
      );
  }
}
