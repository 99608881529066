import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Availability]';

export const availabilityActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,
};

export class AvailabilityAction {
  static load(requestData): Action {
    return {
      type: availabilityActionType.LOAD,
    };
  }

  static loadSuccess(availability): Action {
    return {
      type: availabilityActionType.LOAD_SUCCESS,
      payload: availability,
    };
  }

  static loadFailed(err): Action {
    return {
      type: availabilityActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static addSuccess(response): Action {
    return {
      type: availabilityActionType.ADD_SUCCESS,
      payload: response,
    };
  }

  static addFailed(err): Action {
    return {
      type: availabilityActionType.ADD_FAILED,
      payload: err,
    };
  }
}
