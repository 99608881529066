import { UnsafeAction as Action } from '../../interfaces';
import { ConflictTopic, ScheduleConflictModel, ScheduleConflictStoreModel } from './schedule-conflict.model';

const ACTION_PREFIX = '[ScheduleConflicts]';

export const scheduleConflictActionType = {
  RESET: `${ACTION_PREFIX} Reset`,
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  UPDATE_SINGULAR: `${ACTION_PREFIX} Update Singular`,
  UPDATE_SINGULAR_SUCCESS: `${ACTION_PREFIX} Update Singular Success`,
};

export class ScheduleConflictAction {
  static reset(): Action {
    return {
      type: scheduleConflictActionType.RESET,
    };
  }

  static load(): Action {
    return {
      type: scheduleConflictActionType.LOAD,
    };
  }

  static loadSuccess(scheduleConflicts: ScheduleConflictStoreModel[]): Action {
    return {
      type: scheduleConflictActionType.LOAD_SUCCESS,
      payload: scheduleConflicts,
    };
  }

  static loadFailed(err): Action {
    return {
      type: scheduleConflictActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static update(userIds: string[], timestamp: number): Action {
    return {
      type: scheduleConflictActionType.UPDATE,
      payload: userIds,
      timestamp,
    };
  }

  static updateSuccess(scheduleConflicts: ScheduleConflictStoreModel[], timestamp: number): Action {
    return {
      type: scheduleConflictActionType.UPDATE_SUCCESS,
      payload: scheduleConflicts,
      timestamp,
    };
  }

  static updateSingular(userIds: string[], conflictTopic: ConflictTopic): Action {
    return {
      type: scheduleConflictActionType.UPDATE_SINGULAR,
      payload: userIds,
      conflictTopic,
    };
  }

  static updateSingularSuccess(scheduleConflicts: ScheduleConflictModel[]): Action {
    return {
      type: scheduleConflictActionType.UPDATE_SINGULAR_SUCCESS,
      payload: scheduleConflicts,
    };
  }

  static updateFailed(err): Action {
    return {
      type: scheduleConflictActionType.UPDATE_FAILED,
      payload: err,
    };
  }
}
